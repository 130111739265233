@import url("./mixin.less");
@import url("./compont.less");


#root {
    .App {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.upload-image {
    width: 70rem;
    .center();
    background-color: #fff;
    padding-top: 2rem;

    .upload-component {
        .center();

        .select-image {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .image-label {
            .center();
            width: 64rem;
            cursor: pointer;
            min-height: 20rem;
            font-size: 1.6rem;
            color: #ccc;
            background-color: white;
            border: 2px dashed #ccc;

            &:hover {
                border-color: #333;
                color: #333;
            }
        }
    }

    .display-component {
        img {
            width: 64rem;
            min-height: 30rem;
        }
    }
}

.download-image {
    background-color: rgb(239, 68, 68);
    color: white;
    padding: 1.2rem 0;
    width: 100%;
    border-radius: 2.4rem;
    text-align: center;
    margin: 2rem 0 -2rem 0;
    cursor: pointer;
    font-size: 1.6rem;
}

.infomation {
    @mainColor: #333;
    @subColor: #ccc;
    @mainWeight: 700;
    font-family: 'LK', sans-serif;

    .left {
        .device {
            text-transform: capitalize;
            color: @mainColor;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .time {
            color: @subColor;
            color: #000;
            opacity: .4;
        }
    }

    .right .shot {
        .flex(start, center);

        .dot {
            width: 10px;
            height: 10px;
            background-color: orangered;
            border-radius: 50%;
            margin-right: -3px;

        }

        .text {
            .flex(space-between, center);

            &>div {
                margin-left: 1.2rem
            }
        }
    }

    .gps,
    .time,
    .shot {
        font-family: NotoSansTangsa;
        font-size: 13px;
    }

    &.Horizontal {
        .flex(space-between, center);

        .left,
        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 4rem;
        }

        .right {


            .shot,
            .gps {
                display: flex;
                justify-content: flex-end;
            }

            .gps {
                color: #000;
                opacity: .4;
            }
        }
    }

    &.Vertical {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .device {
            text-shadow: 2px 2px 5px #ccc;
        }

        .top {
            .center();
            height: 2.6rem
        }

        .bottom {
            .flex(space-around, center);
            height: 2.5rem;
        }
    }
}


.operation {
    width: 70rem;
    padding: 2rem;
    margin-top: 4rem;
    border-radius: 1.2rem;
    box-sizing: border-box;
    background-color: #fff;

    .option {
        height: 5.2rem;
        width: 100%;
        .flex(space-between, center);

        .title {
            font-size: 1.6rem;
        }

        .display-wrapper {
            .flex(center, center);

            label {
                font-size: 1.4rem;
            }
        }
    }
}
